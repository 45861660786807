import React from 'react'

function EmptyShipmentTable() {
  return (
  
    <div className="flex  w-full items-center justify-center py-[72px] ">
      <div className=" flex flex-col justify-center">
        <div className='flex justify-center'>
        <img src="/images/new-request.svg" alt="" />
        </div>
        <p className="font-onestRegular mb-2  text-sm text-[#4A564D]">
            You do not have any shipments yet
        </p>
        <p className="font-onestRegular   text-xs text-center text-[#A0ABA3]">
          When you make a shipment, it will show here
        </p>
      </div>
    </div>
  )
}

export default EmptyShipmentTable