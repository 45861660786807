import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface Props{
  "type":string,
  "value":string
}

interface dashboardProps {
  "startDate": string,
    "endDate": string
}

export const getDashboard = createApi({
  reducerPath: "getDashboard",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("token");
      const secretKey = localStorage.getItem("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getStates: builder.query<any, void>({
        query: () => ({
          url: `states`,
          method: 'GET',
        }),
      }),
      validateBusiness: builder.mutation<any, Props>({
        query: (body) => ({
          url: `onboarding/validateData`,
          method: "POST",
          body,
        }),
      }),
      dashboardStats: builder.mutation<any, dashboardProps>({
        query: (body) => ({
          url: `orders/clusterStatsWithDateRange`,
          method: "POST",
          body,
        }),
    })
  }),
});

export const {
  useGetStatesQuery,
  useValidateBusinessMutation,
  useDashboardStatsMutation
} = getDashboard;
