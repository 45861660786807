import { useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import { useDashboardStatsMutation } from "services/slices/dashboard";
import { DELIVERY_NUMBERS, ORDERS_CHAIN } from "../../variables/data";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useGetWalletBalanceQuery } from "services/slices/wallet";
import { formatMoney } from "helpers/formatMoney";
import { Loader } from "components/Loader";
import ModalContainer from "components/Modal";
import { Button } from "components/Button";
import moment from "moment";
import { filterTerms } from "constant/data";
import { getDateRange } from "helpers/getDateRange";
import { handleErrorToast } from "helpers/toastMessage";

interface User {
  userID: string | null;
  "Org Full Name": string | null;
}

function DashboardHome() {
  const [dashboardStats, { isLoading }] = useDashboardStatsMutation();
  const [statistics, setStatistics] = useState<any>({});
  const [total, setTotal] = useState<number>(0);
  const usersDataString = localStorage.getItem("fez-org-details");
  const [filterDate, setFilterDate] = useState<boolean>(false);
  const [selectedPeriod, setSelectedPeriod] = useState("last7days");
  const [selectedPeriodName, setSelectedPeriodName] = useState("Last 7 days");
  const [homeEndDate, setHomeEndDate] = useState<string>(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [homeStartDate, setHomeStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const userData: User = usersDataString && JSON.parse(usersDataString);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");

  const {
    data: walletData,
    isLoading: isWalletLoading,
  } = useGetWalletBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const handlePeriodChange = (value: string) => {
    setSelectedPeriod(value);
  };

  useEffect(() => {
    if (selectedPeriod && selectedPeriod.toLowerCase() !== "custom") {
      const { startDate, endDate } = getDateRange(selectedPeriod);
      setHomeStartDate(startDate);
      setHomeEndDate(endDate);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod === "custom") {
      setShowDateModal(true);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (homeEndDate !== "" && homeStartDate !== "") {
        dashboardStats({
        startDate: homeStartDate,
        endDate: homeEndDate,
      })
        .unwrap()
        .then((data) => {
          setStatistics({
            returned: data?.orderStats[0].Returned,
            deliveryInProgress: data?.orderStats[0]["Delivery In Progress"],
            pickedUp: data?.orderStats[0]["Picked-Up"],
            pendingPickedUp: data?.orderStats[0]["Pending Pick-Up"],
            preparedForDelivery: data?.orderStats[0]["Prepared For Delivery "],
            paid: data?.orderStats[0]["paid"],
            unpaid: data?.orderStats[0]["unpaid"],
            inTransit: data?.orderStats[0]["In Transit Back to you"],
            delivered: data?.orderStats[0]["Delivered"],
          });
          setTotal(
            Number(data?.orderStats[0]["export"] || 0) +
              Number(data?.orderStats[0]["import"] || 0) +
              Number(data?.orderStats[0]["Prepared For Delivery "] || 0) +
              Number(data?.orderStats[0]["safeLocker"] || 0) +
              Number(data?.orderStats[0]["local"] || 0)
          );
        })
        .catch((error) => {
          handleErrorToast(error);
        });
    }
  }, [homeStartDate, homeEndDate]);

  const navigate = useNavigate();

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("YYYY-MM-DD");
      setFormattedDate(dateStart);
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("YYYY-MM-DD");
      setFormattedEndDate(dateStart);
      setEndDate(date);
    }
  };

  const onClearDate = () => {
    setSelectedPeriodName("Last 7 days");
    setSelectedPeriod("last7days");
    setShowDateModal(false);
  };
  const onApplyDate = () => {
    setSelectedPeriodName(`${formattedDate} - ${formattedEndDate}`)
    setHomeStartDate(formattedDate);
    setHomeEndDate(formattedEndDate);
    setShowDateModal(false);
  };

 
  return (
    <>
      <div className="pl-5  pr-5 md:pl-[40px] md:pr-[55px] overflow-auto ">
        <div className="flex flex-col md:flex-row justify-between pt-[60px] font-onestRegular">
          <div className="">
            <h1 className="text-[#0A5001] font-[900] text-xl md:text-2xl font-onestBold">
              Hello{" "}
              {userData &&
              userData?.["Org Full Name"] &&
              userData?.["Org Full Name"]
                ? userData?.["Org Full Name"].charAt(0).toUpperCase() +
                  userData?.["Org Full Name"].slice(1)
                : ""}
            </h1>
            <p className="text-[#A0ABA3] md:text-lg font-onestRegular">
              Get Started: Create Orders and Ship Your Products Today!
            </p>
          </div>
          <div className="mt-6 md:mt-0">
            {isWalletLoading ? (
              <Loader />
            ) : (
              <h1 className="text-[#0A5001] font-[500] text-xl md:text-2xl font-onestRegular">
                ₦{formatMoney().format(walletData?.balance?.business_wallet)}
              </h1>
            )}
            <p className="text-[#A0ABA3] md:text-lg flex md:justify-end font-onestRegular">
              Wallet balance
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 bmd:grid-cols-4 gap-3 md:gap-6 mt-12">
          {ORDERS_CHAIN.map((item) => (
            <div
              onClick={() => navigate(`/dashboard/${item.link}`)}
              key={item.id}
              className={` flex justify-between cursor-pointer items-center border-[1px] border-[#E4EBF5] py-3 rounded-lg pl-[18px] pr-2.5`}
            >
              <h1
                className={`text-[13px] font-[600] font-onestMedium w-[90px]`}
              >
                {item.title}
              </h1>
              <img src={item.image} alt={item.title} />
            </div>
          ))}
        </div>
        <div className="flex relative flex-col md:flex-row md:justify-between gap-[14px] md:gap-0 md:items-center mt-14">
          <h1 className="text-[#071227] font-[500]">Delivery numbers</h1>
          <div
            onClick={() => setFilterDate(!filterDate)}
            className="flex min-w-[110px] items-center gap-1 px-3 py-2 border-[1px] border-[#E4EBF5] rounded-lg cursor-pointer"
          >
            <img
              className="w-3 h-3"
              src="/images/calendar.svg"
              alt="calendar-icon"
            />
            <p className="text-[#4A564D] whitespace-nowrap text-xs font-[600] font-onestMedium">
              {selectedPeriodName}
            </p>
            {filterDate && (
              <div className="p-4 border-[1px] w-[190px] md:right-0 top-20 md:top-11 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                <h1 className="text-[#929DAE] pb-3 font-[900] text-xs font-onestBold">
                  PERIOD
                </h1>
                <form className="">
                  {filterTerms.map((terms) => (
                    <div
                      className="group flex my-3 gap-2.5 hover:bg-[#D4F3D5] text-[#289D17] hover:py-2 rounded-lg pl-2"
                      onClick={() => {
                        setSelectedPeriodName(terms.name);
                        handlePeriodChange(terms.value);
                      }}
                    >
                      <p className="flex items-center mb-0 cursor-pointer ">
                        <span
                          className={`${
                            selectedPeriod === terms.value && "bg-[#289D17]"
                          } w-4 h-4 border border-gray-300 rounded-full flex items-center justify-center group-hover:bg-[#289D17]`}
                        >
                          <span className="w-2 h-2 bg-white rounded-full"></span>
                        </span>
                        <span
                          className={`${
                            selectedPeriod === terms.value
                              ? "text-[#289D17]"
                              : "text-[#464748] group-hover:text-[#289D17]"
                          } ml-3 text-sm  `}
                        >
                          {terms.name}
                        </span>
                      </p>
                    </div>
                  ))}
                </form>
              </div>
            )}
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="grid md:grid-cols-2 bmd:grid-cols-3 gap-x-[31px] gap-y-5 mt-8">
            {DELIVERY_NUMBERS.map((item: any, idx) => (
              <div
                onClick={() =>
                  navigate(
                    `/dashboard/shipments?status=${encodeURIComponent(
                      item.name
                    )}${
                      selectedPeriod !== "custom"
                        ? `&date=${encodeURIComponent(selectedPeriod)}`
                        : `&startDate=${encodeURIComponent(
                            homeStartDate
                          )}&endDate=${encodeURIComponent(homeEndDate)}`
                    }`
                  )
                }
                key={idx}
                className="flex gap-3 rounded-lg items-center cursor-pointer border-[1px] border-[#E4EBF5]"
              >
                <div
                  style={{
                    background: `linear-gradient(to bottom, #ffffff, ${item.color})`,
                  }}
                  className={`rounded-bl-lg rounded-tl-lg px-4   w-[60px] py-6 flex justify-center items-center`}
                >
                  <img src={item.image} alt={item.title} />
                </div>
                <div>
                  <h1 className="text-[#4A564D] text-sm">{item.title}</h1>
                  {item.title.toLowerCase() === "pending pickup" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        statistics.pendingPickedUp &&
                        formatMoney().format(statistics.pendingPickedUp)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "picked up items" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        statistics.pickedUp &&
                        formatMoney().format(statistics.pickedUp)) ||
                        0}
                    </p>
                  )}
                   {item.title.toLowerCase() === "delivery in progress" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        statistics.deliveryInProgress &&
                        formatMoney().format(statistics.deliveryInProgress)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "delivered items" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        statistics.delivered &&
                        formatMoney().format(statistics.delivered)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "in transit back to you" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        statistics.intransit &&
                        formatMoney().format(statistics.intransit)) ||
                        0}
                    </p>
                  )}
                  {/* {item.title.toLowerCase() === "total paid" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        statistics.paid &&
                        formatMoney().format(statistics.paid)) ||
                        0}
                    </p>
                  )} */}
                  {/* {item.title.toLowerCase() === "total unpaid" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        statistics.unpaid &&
                        formatMoney().format(statistics.unpaid)) ||
                        0}
                    </p>
                  )} */}
                  {item.title.toLowerCase() === "returned items" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        statistics.returned &&
                        formatMoney().format(statistics.returned)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "prepared for delivery" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        statistics.preparedForDelivery &&
                        formatMoney().format(statistics.preparedForDelivery)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "total shipment" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(
                        formatMoney().format(total)) ||
                        0}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <ModalContainer
        open={showDateModal}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px] "
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg text-center pb-4 font-onestBold text-[#0A5001] ">
            Custom date range
          </p>
          <div className="flex gap-3 justify-between">
            <div className="">
              <DatePicker
                placeholderText={"Start date"}
                selected={startDate}
                onChange={handleStartDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
            <div className="">
              <DatePicker
                placeholderText={"End date"}
                selected={endDate}
                onChange={handleEndDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
          </div>
          <div className="flex justify-between gap-3 items-center pt-[30px]">
            <Button
              title="Clear"
              onClick={onClearDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#289D17]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12 !bg-[#127E04] !text-[#fff]"
              onClick={onApplyDate}
            />
          </div>
        </div>
      </ModalContainer>
     
    </>
  );
}

export default DashboardHome;
