import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { MdContentCopy, MdOutlineModeEditOutline } from "react-icons/md";
import { IoHelpCircleOutline } from "react-icons/io5";
import { TextInput } from "components/common/form/Input";
import { TextArea } from "components/common/form/textArea";
import { Button } from "components/Button";
import {
  useEditShipmentMutation,
  useGetSingleOrderQuery,
} from "services/slices/shipNow";
import { Loader } from "components/Loader";
import moment from "moment";
import { formatMoney } from "helpers/formatMoney";
import EditShipmentModal from "./EditShipmentModal";
import { handleErrorToast } from "helpers/toastMessage";
import getSymbolFromCurrency from "currency-symbol-map";
import { copyToClipboard } from "helpers/handleCopy";
function ViewShipment() {
  const [open, setOpen] = useState(false);
  const [editDetails, setEditDetails] = useState({
    recipientEmail: "",
    recipientPhone: "",
  });
  const [formErrors, setFormErrors] = useState<any>({});
  const [isAddressVisible, setIsAddressVisible] = useState<boolean>(false);
  const [copyKeySuccess, setCopyKeySuccess] = useState<boolean>(false);
  const [copyOtpSuccess, setCopyOtpSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const { data, error, isLoading, refetch } = useGetSingleOrderQuery(id || "");
  const [
    editShipment,
    { data: editData, isLoading: editLoading, error: editError },
  ] = useEditShipmentMutation();
  const handleClick = () => navigate(-1);

  const validateStep = (values: any) => {
    let errors: any = {};
    if (!values.recipientPhone || values.recipientPhone === "") {
      errors.recipientPhone = "Recipient phone number is required";
    } else if (values.recipientPhone?.length !== 11) {
      errors.recipientPhone = "Recipient phone number must be 11 digits";
    }
    return errors;
  };

  useEffect(() => {
    setEditDetails({
      recipientEmail: data?.orderDetails?.[0]?.recipientEmail,
      recipientPhone: data?.orderDetails?.[0]?.recipientPhone,
    });
  }, [data]);

  const handleEditSave = async () => {
    setFormErrors(validateStep(editDetails));
    const body = [
      {
        recipientEmail: editDetails.recipientEmail,
        recipientPhone: editDetails.recipientPhone,
        orderNo: id,
      },
    ];
    if (
      (editDetails.recipientEmail !== data?.orderDetails?.[0]?.recipientEmail ||
        editDetails.recipientPhone !==
          data?.orderDetails?.[0]?.recipientPhone) &&
      editDetails.recipientPhone !== "" &&
      editDetails.recipientPhone?.length === 11
    ) {
      await editShipment(body)
        .unwrap()
        .then((data) => {
          navigate("/dashboard/shipments");
          setOpen(false);
          refetch();
        })
        .catch((e: any) => {
          handleErrorToast(e);
        });
    }
  };
  const handleCopyKey = () => {
    copyToClipboard(
      `Hello! Your order no is ${
        data?.orderDetails?.[0]?.orderNo
      }, You can track using this link https://web.fezdelivery.co/track-delivery/${
        data?.orderDetails?.[0]?.orderNo
      }${
        data?.orderDetails?.[0]?.orderStatus.toLowerCase() === "dispatched"
          ? ` and your delivery OTP is ${data?.orderDetails?.[0]?.delivery_OTP}`
          : ""
      }, Thank you.`,

      setCopyKeySuccess
    );
  };
  const handleCopyOtp = () => {
    copyToClipboard(data?.orderDetails?.[0]?.delivery_OTP, setCopyOtpSuccess);
  };
  useEffect(() => {
    if (error) {
      handleErrorToast(error);
    }
  }, [error]);

  return (
    <>
      {isLoading ? (
        <div className="my-24">
          <Loader />
        </div>
      ) : (
        <>
          <div className="hidden md:block">
            <div className="px-3 md:px-[42px] mt-[32px] mb-[150px] ">
              <div
                onClick={handleClick}
                className="hidden md:flex gap-1 cursor-pointer w-[100px]"
              >
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
                <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                  Go back
                </p>
              </div>
              <div className="max-w-[778px] border-[1px] border-[#E1F0DF] rounded-lg mx-auto m-5 pt-10">
                <div className="flex justify-between px-[33px] ">
                  <div className="flex items-center space-x-1.5">
                    <h1 className="text-2xl font-onestBold font-[800] text-[#0A5001] tracking-wide">
                      Order ID: {data?.orderDetails?.[0]?.orderNo}
                    </h1>

                    <span
                      className="flex items-center gap-2 border-[1px] px-4 py-1.5 bg-white  rounded-lg border-[#c4c5c4] cursor-pointer"
                      onClick={handleCopyKey}
                    >
                      <MdContentCopy className="text-[#181818]" size={16} />
                      <p className="text-[#181818] font-onestRegular text-sm">
                        {copyKeySuccess ? "Copied!" : "Copy"}
                      </p>
                    </span>
                  </div>

                  {data?.orderDetails?.length > 0 && (
                    <div className="flex">
                      <span
                        className="text-sm cursor-pointer text-[#A32596] flex items-center gap-1  px-2 py-1 rounded-md font-[400] font-onestRegular"
                        onClick={() =>
                          navigate(`/dashboard/tickets/create-ticket?id=${id}`)
                        }
                      >
                        <IoHelpCircleOutline />
                        Raise ticket
                      </span>
                      {data?.orderDetails?.[0]?.orderStatus?.toLowerCase() !==
                        "pickedup" &&
                        data?.orderDetails?.[0]?.orderStatus?.toLowerCase() !==
                          "delivered" && (
                          <div
                            onClick={() => setOpen(true)}
                            className="text-sm cursor-pointer flex items-center gap-1 text-[#1D4CC4] px-2 py-1 rounded-md font-[400] font-onestRegular"
                          >
                            <MdOutlineModeEditOutline />
                            Edit
                          </div>
                        )}
                    </div>
                  )}
                </div>

                <div className="flex bg-white border-t justify-between mt-[30px] py-4 px-[33px]">
                  <h1 className="font-onestRegular">Recipient Name</h1>
                  <p className="font-onestRegular">
                    {data?.orderDetails?.[0]?.recipientName || "N/A"}
                  </p>
                </div>
                <div className="flex bg-[#F4F5F6] justify-between py-4  px-[33px]">
                  <h1 className="font-onestRegular ">Recipient Address</h1>
                  <p> {data?.orderDetails[0]?.recipientAddress || "N/A"}</p>
                </div>
                <div className="flex bg-white  justify-between  py-4  px-[33px]">
                  <h1 className="font-onestRegular ">Recipient Phone</h1>
                  <p className="font-onestRegular ">
                    {data?.orderDetails?.[0]?.recipientPhone || "N/A"}
                  </p>
                </div>
                <div className="flex justify-between bg-[#F4F5F6] py-4  px-[33px]">
                  <h1 className="font-onestRegular ">Recipient Email</h1>
                  <p> {data?.orderDetails?.[0]?.recipientEmail || "N/A"}</p>
                </div>
                <div className="flex font-onestRegular justify-between bg-white  py-4 px-[33px]">
                  <div className="flex items-center space-x-2  pr-4">
                    <h1 className="font-onestRegular ">Pick up address</h1>
                    <img
                      src={
                        isAddressVisible
                          ? "/images/show-password.svg"
                          : "/images/hide-password.png"
                      }
                      alt={
                        isAddressVisible
                          ? "show-password-icon"
                          : "hide-password-icon"
                      }
                      className="h-5 w-5 cursor-pointer"
                      onClick={() => setIsAddressVisible(!isAddressVisible)}
                    />
                  </div>
                  <p className="font-onestRegular ">
                    {isAddressVisible
                      ? data?.orderDetails?.[0]?.clientAddress || "N/A"
                      : "*".repeat(20)}
                  </p>
                </div>
                <div className="flex justify-between py-4 bg-[#F4F5F6]   px-[33px]">
                  <h1 className="font-onestRegular">Status</h1>
                  <p className="text-xs text-[#65513F] bg-[#F8E4D1] px-2 py-1 rounded-md font-[500] font-onestMedium">
                    {data?.orderDetails?.[0]?.orderStatus || "N/A"}
                  </p>
                </div>
                <div className="flex justify-between py-4  px-[33px]">
                  <h1 className="font-onestRegular ">Status Description</h1>
                  <p className="font-onestRegular ">
                    {data?.orderDetails?.[0]?.statusDescription || "N/A"}
                  </p>
                </div>

                <div className="flex justify-between bg-[#F4F5F6] py-4 px-[33px]">
                  <h1 className="font-onestRegular">Cost</h1>
                  <p className="font-onestRegular ">
                    {getSymbolFromCurrency(data?.orderDetails?.[0]?.currency)}{" "}
                    {formatMoney().format(data?.orderDetails?.[0]?.cost)}
                  </p>
                </div>
                <div className="flex justify-between py-4 px-[33px]">
                  <h1 className="font-onestRegular ">Created By</h1>
                  <p className="font-onestRegular ">
                    {" "}
                    {data?.orderDetails?.[0]?.createdBy || "N/A"}
                  </p>
                </div>
                <div className="flex justify-between bg-[#F4F5F6] py-4 px-[33px]">
                  <h1 className="font-onestRegular ">Pick Up Date</h1>
                  <p className="font-onestRegular ">
                    {data?.orderDetails?.[0]?.pickUpDate
                      ? moment(data?.orderDetails?.[0]?.pickUpDate).format(
                          "DD MMM, YYYY"
                        )
                      : "N/A"}
                  </p>
                </div>

                <div className="flex justify-between py-4 px-[33px]">
                  <h1 className="font-onestRegular ">Dispatch Date</h1>
                  <p className="font-onestRegular ">
                    {data?.orderDetails?.[0]?.dispatchDate
                      ? moment(data?.orderDetails?.[0]?.dispatchDate).format(
                          "DD MMM, YYYY"
                        )
                      : "N/A"}
                  </p>
                </div>

                <div className="flex w-full bg-[#F4F5F6]  justify-between py-4 px-[33px]">
                  <div className="w-full">
                    <h1 className="font-onestRegular ">Delivery Code/OTP</h1>
                  </div>
                  {data?.orderDetails?.[0]?.orderStatus.toLowerCase() ===
                    "dispatched" ||
                  data?.orderDetails?.[0]?.orderStatus.toLowerCase() ===
                    "delivered" ? (
                    <div className="flex whitespace-nowrap  items-center space-x-1.5">
                      <span
                        className=" text-sm cursor-pointer"
                        onClick={handleCopyOtp}
                      >
                        {copyOtpSuccess ? (
                          "Copied!"
                        ) : (
                          <MdContentCopy className="text-[#181818]" size={16} />
                        )}
                      </span>
                      <p className="font-onestRegular ">
                        {data?.orderDetails?.[0]?.delivery_OTP}
                      </p>
                    </div>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
                <div className="flex justify-between py-4  px-[33px]">
                  <h1 className="font-onestRegular ">Reason for Return</h1>
                  <p className="font-onestRegular ">
                    {data?.orderDetails?.[0]?.returnReason || "N/A"}
                  </p>
                </div>
                <div className="flex justify-between bg-[#F4F5F6]   py-4 px-[33px]">
                  <h1 className="font-onestRegular ">Delivery Date</h1>
                  <p className="font-onestRegular ">
                    {data?.orderDetails?.[0]?.deliveryDate
                      ? moment(data?.orderDetails?.[0]?.deliveryDate).format(
                          "DD MMM, YYYY"
                        )
                      : "N/A"}
                  </p>
                </div>
                <div className="flex justify-between  py-4 px-[33px]">
                  <h1 className="font-onestRegular ">Additional Note</h1>
                  <p className="font-onestRegular ">
                    {data?.orderDetails?.[0]?.additionalNote || "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div className="my-10">
              <EditShipmentModal
                data={data}
                open={open}
                setOpen={setOpen}
                handleEditSave={handleEditSave}
                editLoading={editLoading}
                setEditDetails={setEditDetails}
                editDetails={editDetails}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
            </div>
          </div>

          <div className="block md:hidden">
            <div
              onClick={() => navigate(-1)}
              className="mx-5 mt-9 flex cursor-pointer"
            >
              <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              <p className=" text-[#4a564d] font-[500] text-[13px]  font-onestMedium">
                Go back
              </p>
            </div>
            <div className="mt-[20px] sm:mt-[40px] rounded-lg max-w-[38rem] mx-4 md:mx-auto border-[1px] mb-[100px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
              <div className="w-full px-4 sm:px-4 lg:px-8 py-6 sm:py-8 onestBold">
                <p className="text-lg pb-4 font-onestBold text-[#0A5001] ">
                  Edit Shipment
                </p>
                <p className="font-onestMedium font-[500] text-sm text-[#a0aba3]">
                  Make changes to your order
                </p>
                <div>
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Recipient name"
                      isRequired
                      disabled
                      value={data?.orderDetails[0]?.recipientName}
                      placeholder="Enter name"
                    />
                  </div>
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Recipient phone number"
                      isRequired
                      onChange={(e: any) => {
                        if (e.target.value.length === 11) {
                          setFormErrors({});
                        }
                        setEditDetails((prev: any) => ({
                          ...prev,
                          recipientPhone: e.target.value,
                        }));
                      }}
                      value={editDetails.recipientPhone}
                      placeholder="Enter phone number"
                    />
                    {formErrors.recipientPhone && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.recipientPhone}
                      </span>
                    )}
                  </div>
                  <div className="mt-6">
                    <TextInput
                      type="email"
                      label="Recipient email"
                      placeholder="Enter email"
                      onChange={(e: any) =>
                        setEditDetails((prev: any) => ({
                          ...prev,
                          recipientEmail: e.target.value,
                        }))
                      }
                      value={editDetails.recipientEmail}
                    />
                  </div>
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Recipient State"
                      isRequired
                      value={data?.orderDetails[0]?.recipientAddress}
                      disabled
                      placeholder="Select state"
                    />
                  </div>
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Drop-off address"
                      isRequired
                      value={data?.orderDetails[0]?.recipientAddress}
                      disabled
                      placeholder="Enter address"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-x-3">
                    <div className="mt-6">
                      <TextInput
                        type="text"
                        label="Quantity"
                        disabled
                        value={data?.orderDetails?.[0]?.quantity}
                        placeholder="Enter quantity"
                      />
                    </div>
                    <div className="mt-6">
                      <TextInput
                        type="text"
                        label="Item weight"
                        isRequired
                        disabled
                        value={data?.orderDetails?.[0]?.weight}
                        placeholder="Enter weight"
                      />
                    </div>
                  </div>
                  <div className="mt-6">
                    <TextArea
                      label="Item description"
                      value={data?.orderDetails?.[0]?.additionalNote}
                      placeholder="Write a description"
                    />
                  </div>
                  <div className="flex justify-between items-center pt-[30px]">
                    <div className="flex-col w-full">
                      <Button
                        onClick={handleEditSave}
                        disabled={editLoading}
                        loading={editLoading}
                        title="Save"
                        className="w-full !py-[10px] !px-6 !rounded-[8px] !text-white !border !bg-[#289D17]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ViewShipment;
