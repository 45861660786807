import {
  ChangeEvent,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  useAddTicketMessageMutation,
  useCloseTicketMutation,
  useGetSingleTicketQuery,
  useReOpenTicketMutation,
} from "services/slices/ticket";
import { useNavigate } from "react-router";
import { Loader } from "components/Loader";
import moment from "moment";
import { Button } from "components/Button";
import Card from "components/dashboard/Card";
import useFetchAllPages from "hooks/useFetchAllPages";
import { handleErrorToast } from "helpers/toastMessage";

function Ticketing() {
  const { id } = useParams<{ id: string }>();
  const { allMessages, isLoadingAll } = useFetchAllPages(id || "");
  const [ticketMessage, setTicketMessage] = useState<string>("");
  const userData: any = localStorage.getItem("fez-user");
  const [temporaryMessages, setTemporaryMessages] = useState<any>([]);
  const [rows, setRows] = useState(1);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const handleClick = () => navigate(-1);
  const [addTicketMessage, { isLoading }] =
    useAddTicketMessageMutation();
  const [closeTicket, { isLoading: isClosing,  }] =
    useCloseTicketMutation();
  const [openTicket, { isLoading: isOpening,}] =
    useReOpenTicketMutation();

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const lineCount = e.target.value.split("\n").length;
    setRows(Math.min(lineCount, 4));
    setTicketMessage(e.target.value);
  };
  useLayoutEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [allMessages, temporaryMessages]);

  const user = JSON.parse(userData);
  const {
    isLoading: ticketLoading,
    data: singleTicket,
    refetch,
  } = useGetSingleTicketQuery(id as string, {
    skip: !id,
  });
  const handleReOpenTicket = async () => {
    if (id) {
      await openTicket(id)
        .unwrap()
        .then((data: any) => {
          refetch();
        })
        .catch((e) => {
          handleErrorToast(e)

        });
    }
  };

  const onCloseTicket = async () => {
    if (id) {
      await closeTicket(id)
        .unwrap()
        .then((data: any) => {
          refetch();
        })
        .catch((e) => {
          handleErrorToast(e)

        });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); 
      if (ticketMessage.trim() !== "") {
        onSubmitTicket(); 
      }
    }
  };

  const onSubmitTicket = async () => {
    await addTicketMessage({
      ticketId: `${id}`,
      message: ticketMessage,
    })
      .unwrap()
      .then((data: any) => {
        setTemporaryMessages([
          ...temporaryMessages,
          {
            id: `temp-${Date.now()}`,
            ticket_id: id,
            message: ticketMessage,
            user_id: user.userID,
            orguser: {
              fullname: user?.["Full Name"],
            },
          },
        ]);

        setTicketMessage("");
      })
      .catch((e: any) => {
        handleErrorToast(e)

      });
  };

  if (!id) {
    return <div>No Ticket ID provided</div>;
  }


  return (
    <div className=" relative flex w-full justify-center items-center px-5 lg:h-[75%]">
      <div className="md:fixed lg:top-10 top-5 flex justify-center  w-full px-5  h-fit">
        <Card className=" md:max-w-[38rem] w-full flex flex-col">
          <div className="flex justify-between items-center">
            <div
              onClick={() => handleClick()}
              className="flex mb-2 w-fit cursor-pointer"
            >
              <div>
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Go back
              </p>
            </div>

            {singleTicket?.Tickets?.status?.toLowerCase().includes("open") && (
              <Button
                title="Close ticket"
                loading={isClosing}
                disabled={isClosing}
                onClick={onCloseTicket}
                className="!bg-[#45C734]/25 rounded-lg text-xs !text-[#17730A] font-[500] font-onestMedium !px-2 !py-1.5"
              />
            )}
          </div>

          {ticketLoading ? (
            <Loader />
          ) : (
            <div>
              <div className="flex gap-3 mt-[34px]">
                <div>
                  <h1 className="text-lg font-onestBold font-[800] text-[#0E1728] tracking-wide">
                    TICKET {id || "N/A"}
                  </h1>
                  <p className="font-onestRegular text-sm text-[#4A564D]">
                    {moment(singleTicket?.Tickets?.created_at).format(
                      "ddd D MMM, YYYY"
                    )}
                  </p>
                </div>
                <div className="bg-[#45C734]/25 mt-1 rounded-[4px] p-[4px] h-full text-[#0A5001] text-xs font-onestMedium font-[500]">
                  {singleTicket?.Tickets?.status?.toLowerCase().includes("open")
                    ? "open"
                    : "closed"}
                </div>
              </div>
              <h1 className="text-lg mt-7 font-onestBold font-[800] text-[#0E1728] tracking-wide">
                Subject: {singleTicket?.Tickets?.subject?.["Subjects"] || "N/A"}
              </h1>
            </div>
          )}

          <div className="overflow-y-auto h-[350px] pb-4 px-2">
            {allMessages.length === 0 && isLoadingAll ? (
              <div className="mt-20">
                <Loader />
              </div>
            ) : (
              <>
                {allMessages.length > 0 ? (
                  <>
                    {[...allMessages, ...temporaryMessages].map(
                      (message, index) => (
                        <div key={index}>
                          <div
                            className={`mt-4 ${
                              message?.user_id === user?.userID &&
                              "flex justify-end"
                            }`}
                          >
                            <div>
                              <p
                                className={`text-[#929DAE] ${
                                  message?.user_id === user?.userID
                                    ? "text-right "
                                    : "text-start "
                                } text-sm font-[500] font-onestMedium mb-1`}
                              >
                                {message?.orguser?.fullname|| message?.content_provider?.username}
                              </p>
                              <div className="border-[1px] text-start w-fit p-2.5 border-[#e1f0df] rounded-lg">
                                <p className="text-sm font-onestRegular text-[#0e1728]">
                                  {message?.message}
                                </p>
                              </div>
                            </div>
                          </div>
                          {index ===
                            [...allMessages, ...temporaryMessages].length -
                              1 && <div ref={lastMessageRef}></div>}
                        </div>
                      )
                    )}
                  </>
                ) : (
                  <div className="flex justify-center text-center">
                    <p className="text-sm font-onestRegular text-[#0e1728]">
                      No message yet! send a message
                    </p>
                  </div>
                )}
              </>
            )}
          </div>

          {singleTicket?.Tickets?.reopen_count > 0 && (
            <div className="flex justify-center gap-3 items-center">
              <p className="w-[25%] h-[1px] bg-[#C0C0C0]"></p>
              <p className="text-sm text-[#68766B] font-onestRegular font-[400]">
                Ticket re-opened on{" "}
                {moment(singleTicket?.Tickets?.last_reopen_date).format(
                  "MMM DD, YYYY"
                )}
              </p>
              <p className="w-[25%] h-[1px] bg-[#C0C0C0]"></p>
            </div>
          )}

          <div className="mt-auto w-full  pt-4">
            {Boolean(
              singleTicket?.Tickets?.status?.toLowerCase().includes("open")
            ) ? (
              <div className="flex   border-[1px] p-0.5 border-[#929dae] rounded-lg">
                <textarea
                  className="outline-none w-[80%] px-2"
                  value={ticketMessage}
                  rows={rows}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Type your reply..."
                />
                <div className="flex justify-end w-[20%] flex-col">
                  <Button
                    title="Send"
                    className={`!py-2 !bg-[#18AF04]`}
                    type="submit"
                    loading={isLoading}
                    disabled={ticketMessage === "" || isLoading}
                    onClick={onSubmitTicket}
                  />
                </div>
              </div>
            ) : (
              <div className="flex justify-between items-center">
                <p className="text-sm text-[#68766B] font-onestRegular font-[400]">
                  This ticket was closed on{" "}
                  {moment(singleTicket?.Tickets?.closed_date).format(
                    "MMM DD, YYYY"
                  )}
                </p>
                <Button
                  loading={isOpening}
                  disabled={isOpening}
                  title="Re-open Ticket"
                  onClick={handleReOpenTicket}
                  className="!bg-[#45C734]/25 rounded-lg !text-xs px-4 !text-[#17730A] !font-[500] font-onestMedium  !py-2.5"
                />
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Ticketing;
