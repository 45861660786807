import React, { useEffect, useState } from "react";
import TopNav from "../dashboard/TopNav";
import Sidebar from "../dashboard/Sidebar";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const DashboardLayout = ({ children }: any) => {
  const navigate = useNavigate();

  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const [banner, setBanner] = useState<string>("");

  const handleClick = () => {
    setMobileMenu(!mobileMenu);
  };
  useEffect(() => {
    localStorage.setItem("set-banner", "yes");
  }, []);
  useEffect(() => {
    setBanner(localStorage.getItem("set-banner") || "");
  }, [localStorage]);
  return (
    <div className="max-h-[100vh]  overflow-hidden">
      <div className="">
        {banner === "yes" && (
          <div className="bg-[#00101C] max-h-[200px] md:px-0 px-2 pt-3 pb-6 text-center md:text-start  space-y-2 md:space-y-0 md:space-x-4 flex md:flex-row flex-col md:items-center md:justify-center">
            <div
              className="md:hidden cursor-pointer flex  justify-end"
              onClick={() => {
                localStorage.setItem("set-banner", "no");
                setBanner("no");
              }}
            >
              <IoClose className="text-[#9CA6A2] text-2xl" />
            </div>
            <p className="text-white  text-lg md:text-[28px] font-onestRegular md:leading-[30px] marquee">
            Fez Festive Discount Is Here! Use {" "} 
              <span className="text-[#93E596] font-onestMedium ">FEZEMBER10</span>{" "}
              to save 10% on all deliveries until December 31st
            </p>

            <div
              className="bg-white whitespace-nowrap z-[100] cursor-pointer px-6 py-2.5 rounded-[100px] "
              onClick={() => {
                localStorage.setItem("set-banner", "no");
                setBanner("no");
                navigate(`/dashboard/ship-now`);
              }}
            >
              <p className="font-onestBold text-[#0E3347]"> Ship now!</p>
            </div>
            <div
              className="md:block hidden z-[100] cursor-pointer"
              onClick={() => {
                localStorage.setItem("set-banner", "no");
                setBanner("no");
              }}
            >
              <IoClose className="text-[#9CA6A2] text-2xl" />
            </div>
          </div>
        )}
        <TopNav onClick={handleClick} />
      </div>
      <div className="flex">
        <div className="">
          <Sidebar hamburger={mobileMenu} onClose={handleClick} />
        </div>
        <div
          className={`w-full mb-6  md:border-l-[2px] border-[#E4EBF5] ${banner==="yes"?"md:max-h-[calc(100vh-180px)] max-h-[calc(100vh-260px)]":"max-h-[calc(100vh-80px)] md:max-h-[calc(100vh-80px)]"} overflow-auto `}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
