import React from "react";
import PublicRoutes from "components/common/Routes/PublicRoute";
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import NotFound from "pages/notFound";
import ForgotPassword from "pages/auth/ForgotPassword";
import DashboardHome from "pages/dashboard/Home";
import BulkShipment from "pages/dashboard/ship-now/BulkShipment";
import ChangePassword from "pages/auth/ChangePassword";
import CreateUsers from "pages/dashboard/users/create";
import EditUsers from "pages/dashboard/users/EditUser";
import Dashboard from "components/layout/DashboardFrame";
import BusinessRegister from "pages/auth/BusinessRegister";
import Pricing from "pages/dashboard/pricing/Pricing";
import Users from "pages/dashboard/users";
import SingleShipment from "pages/dashboard/ship-now/SingleShipment";
import AllTickets from "pages/dashboard/tickets/AllTickets";
import CreateTicket from "pages/dashboard/tickets/CreateTicket";
import Ticketing from "pages/dashboard/tickets/TicketMessage";
import Developer from "pages/dashboard/Developer";
import Reports from "pages/dashboard/Reports";
import ImportOrder from "pages/dashboard/bumpa/ImportOrder";
import ManageKey from "pages/dashboard/bumpa/ManageKey";
import Shipments from "pages/dashboard/shipment/Shipments";
import Wallet from "pages/dashboard/Wallet";
import PrintManifest from "pages/dashboard/print-manifest/PrintManifest";
import ViewShipment from "pages/dashboard/shipment/ViewShipment";
import Setting from "pages/dashboard/settings/Setting";
import Notification from "pages/dashboard/Notification";
import Help from "pages/dashboard/Help";
import PricingTable from "pages/dashboard/pricing/PricingTable";
import Invoice from "pages/dashboard/invoice";
import InvoiceDetails from "pages/dashboard/invoice/InvoiceDetails";

const routes = [
  {
    path: "/",
    element: (
      <PublicRoutes>
        <Login />
      </PublicRoutes>
    ),
  },
  {
    path: "/register",
    element: (
      <PublicRoutes>
        <Register />
      </PublicRoutes>
    ),
  },
  {
    path: "/business-register",
    element: (
      <PublicRoutes>
        <BusinessRegister />
      </PublicRoutes>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <PublicRoutes>
        <ForgotPassword />
      </PublicRoutes>
    ),
  },
  {
    path: "/change-password",
    element: (
      <PublicRoutes>
        <ChangePassword />
      </PublicRoutes>
    ),
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        index: true,
        element: <DashboardHome />,
      },
      {
        path: "ship-now",
        element: <SingleShipment />,
      },
      {
        path: "ship-now/bulk-upload",
        element: <BulkShipment />,
      },
      {
        path: "shipments",
        element: <Shipments />,
      },
      {
        path: "shipments/:id",
        element: <ViewShipment />,
      },
      {
        path: "wallet",
        element: <Wallet />,
      },
      {
        path: "users/create-user",
        element: <CreateUsers />,
      },
      {
        path: "users/edit-user/:id",
        element: <EditUsers />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "pricing/list",
        element: <PricingTable />,
      },
      {
        path: "tickets/create-ticket",
        element: <CreateTicket />,
      },
      {
        path: "ticket",
        element: <AllTickets />,
      },
      {
        path: `ticket/:id`,
        element: <Ticketing />,
      },
      {
        path: "developer",
        element: <Developer />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "import-orders",
        element: <ImportOrder />,
      },
      {
        path: "print-manifest",
        element: <PrintManifest />,
      },
      // {
      //   path: "invoice",
      //   element: <Invoice />,
      // },
      // {
      //   path: `invoice/:id`,
      //   element: <InvoiceDetails />,
      // },
      {
        path: "manage-keys",
        element: < ManageKey/>,
      },
      {
        path: "help",
        element: <Help/>,
      },
      {
        path: "setting",
        element: <Setting/>,
      },
      {
        path: "notification",
        element: <Notification/>,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
